import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How We Secured Our Url Forwarder with JWT",
  "author": "tzookb",
  "type": "post",
  "date": "2018-12-06T08:15:37.000Z",
  "url": "/2018/12/how-we-secured-our-url-forwarder-with-jwt/",
  "categories": ["Programming"],
  "tags": ["hacks", "JWT"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Wrong password`}{`…`}</p>
    <p>{`typing my credentials again, and same annoying red box `}{`“`}{`Wrong password`}{`”`}</p>
    <p>{`Now as I`}{`’`}{`m a good developer with a nice secure password stored in my `}{`“`}{`password manager`}{`”`}{`, I open the password manager software and pull out my `}{`“`}{`may have forgotten`}{`”`}{` password and type it again`}{`…`}</p>
    <p>{`“`}{`Wrong password`}{`”`}</p>
    <p>{`…`}{` What is happening??`}</p>
    <p>{`Well, I won`}{`’`}{`t bother you with all the details, although this story didn`}{`’`}{`t happen to me (luckily) it surely happened to many others. This could happen to the more `}{`“`}{`advanced`}{`”`}{` users and not only tor moms and paps`}{`…`}</p>
    <p>{`Think about this situation. You get an email/link/post whatever`}{`…`}{` With the link:`}</p>
    <blockquote>
      <p parentName="blockquote">{`SomeRecognizedWebsite.com/forward/?d=hackme.com`}</p>
    </blockquote>
    <p>{`So if you see a big brand company you already know and trust in most cases you won`}{`’`}{`t worry too much about clicking on their links right?`}</p>
    <p>{`If you see a link from Google or Facebook you will probably just go with the flow and probably trust the page that will load`}{`…`}</p>
    <p>{`The problem here there are some `}{`“`}{`smart`}{`”`}{` hackers that take advantage of companies forward services and create links that will redirect you to their own malicious websites and then could easily pull your passwords out of you just like in the story above.`}</p>
    <p>{`In the link, you can see a valid known domain `}{`“`}{`SomeRecognizedWebsite.com`}{`”`}{` and some text (most people don`}{`’`}{`t care much about). But this link could forward you to `}{`“`}{`hackme.com`}{`”`}{` or whatever domain I could put there`}{`…`}</p>
    <p>{`So why the heck am I telling you about all the above??`}</p>
    <p>{`This is just the preface to our solution for the above problem where we used JWT tokens to secure our urls and make sure hackers would not use our credibility for their advantage.`}</p>
    <p>{`So instead of having this URL:`}</p>
    <p>{`SomeRecognizedWebsite.com/forward/?d=hackme.com`}</p>
    <p>{`We are using:`}</p>
    <p>{`SomeRecognizedWebsite.com/forward/?d=xxxxx.BIG_JWT_TOKEN.zzzzz`}</p>
    <p>{`this JWT token is signed by our backend service and its readable to everyone so we are not hiding anything here but when this JWT token will be read by our backend service it could validate it wasn`}{`’`}{`t manipulated.`}</p>
    <p>{`So this is the flow:`}</p>
    <ul>
      <li parentName="ul">{`We create a forwarding JWT to `}{`“`}{`social-site.com`}{`”`}{` with a key-value => `}
        <ul parentName="li">
          <li parentName="ul">{`redirectTo = `}{`“`}{`otherwebsite.com`}{`”`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Let`}{`’`}{`s say the generated token is this `}<strong parentName="li">{`“`}{`jwt`}{`_`}{`redirect`}{`_`}{`token`}{`”`}</strong></li>
      <li parentName="ul">{`Now we can share our redirect link service `}{`“`}{`oursite.com/forward/`}<strong parentName="li">{`jwt`}{`_`}{`redirect`}{`_`}{`token`}</strong>{`“`}</li>
      <li parentName="ul">{`Someone clicks on our link`}</li>
      <li parentName="ul">{`Our service takes the token `}<strong parentName="li">{`“`}{`jwt`}{`_`}{`redirect`}{`_`}{`token`}{`”`}{` `}</strong>{` and validates that it was signed by us.`}</li>
      <li parentName="ul">{`We parse the token and extract the value for `}{`“`}{`redirectTo`}{`”`}</li>
      <li parentName="ul">{`Now we simply redirect the user to the extracted value from `}{`“`}{`redirectTo`}{`”`}</li>
    </ul>
    <p>{`Now you can see our `}{`“`}{`URL forwarder`}{`”`}{` is safe and people can`}{`’`}{`t take advantage of our site credibility.`}</p>
    <p><strong parentName="p">{`Some notes that can help:`}</strong></p>
    <ul>
      <li parentName="ul">{`You can add much more data than just a destination URL. You can add source, campaign, or any other relevant data that can benefit the business needs.`}</li>
      <li parentName="ul">{`On the other hand`}{`…`}{` don`}{`’`}{`t get too crazy as there are some limitations to URL length (I think 1024. Google it)`}</li>
      <li parentName="ul">{`If you need to have a lot of data connected to those URLs, you can always set an ID in the URL and store all of that data in a key-value storage that you could fetch when the URL is triggered.`}</li>
    </ul>
    <p><strong parentName="p">{`Implementation:`}</strong></p>
    <p>{`I would be happy to share some code but JWT libraries are quite easy to use and it is supported in a HUGE range of languages. For more info on JWT and libraries go to `}<a parentName="p" {...{
        "href": "https://jwt.io/"
      }}>{`jwt.io`}</a>{`.`}</p>
    <p>{`If you want me to share some examples just comment below.`}</p>
    <p>{`I would be happy to hear any other use cases you have used JWT`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      